import React from "react";
import styled from "styled-components";
import { Headline } from "./type";
import { GridWrapper } from "./layout-components";
import Box from "./john-motion/motion-box";
import Button from "./globals/button";
import { isEvenOdd } from "../functions/util";
import Tick from "./tick";

const SpacesSection = styled(GridWrapper)`
  max-width: 100vw;
  overflow: hidden;
  position: relative;
  grid-template-rows: 1fr;
  grid-template-columns: auto;
  justify-items: center;
  padding: 5vw 0 5vw;
  margin: 3rem auto;
  gap: 1rem;

  .h6 {
    margin-bottom: 3rem;
  }
`;

const TickerWrap = styled(Box)`
  max-width: 100vw;
  overflow: hidden;
`;

const occasions = [
  "Quinces",
  "Anniversaries",
  "Birthdays",
  "Weddings",
  "Engagement Parties",
  "Beauty Pagaents",
  "Dating Mixers",
  "Corporate Off-sites",
  "Benefits & Fundraisers",
  "Sports Banquets",
  "Holiday Parties",
  "Celebrations of Life",
  "Social Mixers",
  "Bridal Showers",
  "Baby Showers",
];

const occasionsPortions = occasions.length / 3;

const occasionsList = [
  [...occasions.slice(0, occasionsPortions)],
  [...occasions.slice(occasionsPortions, occasionsPortions * 2)],
  [...occasions.slice(occasionsPortions * 2, occasionsPortions * 3)],
];

export default function HomepageOccasions() {
  return (
    <SpacesSection>
      <Headline tag="p" size="h6" lineLength={3}>
        All Occasions Welcome
      </Headline>
      <TickerWrap
        hiddenStyles={{ x: 0, scale: 1.2 }}
        visibleStyles={{ scale: 1 }}
      >
        {occasionsList.map((item, i) => {
          const isEven = isEvenOdd(i) === "even" ? "toLeft" : "toRight";
          return (
            <Tick key={i} direction={isEven} tickerSpeed={3}>
              {item.map((listItems, idx) => (
                <p key={idx} className="h2">
                  {listItems}
                  <span className="sans">{" — "}</span>
                </p>
              ))}
            </Tick>
          );
        })}
      </TickerWrap>
      {/* <Button to="/events" text="Learn More" color="nude" /> */}
    </SpacesSection>
  );
}
